import React, { Component } from 'react';

import Loading from './Loading';
import QuestionContent from './QuestionContent';
import SimulationOverview from './SimulationOverview';
import SetATODetails from '././SetATODetails';

import _ from 'underscore';

import API from '../utils/resources/api.js';
import utils from '../utils/utils';
import svgs from '../utils/svgs';

import i18n from "../utils/i18n";

import './css/quizTemplate.css';

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

let TOTAL_EXAM_QUESTIONS = 16;
let TOTAL_RADIO_EXAM_QUESTIONS = 16;
let TOTAL_FLIGHTSCHOOL_QUESTIONS = parseInt(TOTAL_EXAM_QUESTIONS / 4);
let TOTAL_FLIGHTSCHOOL_SECRET_QUESTIONS = parseInt(TOTAL_FLIGHTSCHOOL_QUESTIONS * 0.75);
let TOTAL_FLIGHTSCHOOL_PUBLIC_QUESTIONS = parseInt(TOTAL_FLIGHTSCHOOL_QUESTIONS - TOTAL_FLIGHTSCHOOL_SECRET_QUESTIONS);
let POSITIVE_PERCENTAGE_NUMBER = 75;

class QuizTemplate extends Component {
  constructor(props) {
    super(props);

    const userinfo = this.props.userinfo;
    const atoDetails = this.props.atoDetails;
    let isATO = (userinfo && userinfo.idToken && userinfo.idToken.claims && userinfo.idToken.claims.isATO) || false;
    let atoFullName = (atoDetails && atoDetails.atoFullName) || null;

    // when is useradmin set as ATO to get more details
    const isAdmin = this.props.atoDetails && this.props.atoDetails.isAdmin; 
    if (isAdmin) {
      isATO = true;
      atoFullName = this.props.atoDetails.atoFullName;
    }

    this.state = {
      mode: this.props.mode,
      categories: this.props.categories,
      isLoading: true,
      isATO: isATO,
      atoFullName, 
      user: null,
      questions: this.props.questions,
      scenario: this.props.scenario,
      printNewExam: null,
      isExamAndHasID: this.props.isExamAndHasID,
    };

    this.keys = {
      PPLA: ["PPLA"],
      PPLH: ["PPLH"],
      SPL: ["SPL"],
      BPL: ["BPLH", "BPLG"],
    };

    this.getOrCreateLocalStorageQuestionDatabase = this.getOrCreateLocalStorageQuestionDatabase.bind(this);
    this.preparedQuestionObject = this.preparedQuestionObject.bind(this);
    this.getQuestionsForSimulationFromKey = this.getQuestionsForSimulationFromKey.bind(this);
    this.onReset = this.onReset.bind(this);
    this.backToSimulationOverview = this.backToSimulationOverview.bind(this);
    this.prepareQuestions = this.prepareQuestions.bind(this);
    this.sendExam = this.sendExam.bind(this);
    this.getUserEmail = this.getUserEmail.bind(this);
    this.saveExamAsExam = this.saveExamAsExam.bind(this);
    this.saveExamAsHomework = this.saveExamAsHomework.bind(this);
    this.updateATODetails = this.updateATODetails.bind(this);
    this.generatePDF = this.generatePDF.bind(this);
    this.getAnswersBody = this.getAnswersBody.bind(this);
    this.createExamForPrinter = this.createExamForPrinter.bind(this);
    this.getTranslation = this.getTranslation.bind(this);

    // initially update question count e.g. for external homework or exams
    if (this.props.homeworkQuestionCount) {
      this.updateQuestionCounts(this.props.homeworkQuestionCount, this.props.homeworkQuestionCount);
    }
    if (this.props.homeworkExamPercentage) {
      this.updateExamPercentage(this.props.homeworkExamPercentage);
    }
  }

  componentDidMount() {
    if (this.state.mode !== "simulation") {
      this.prepareQuestions();
    } else {
      this.setState({ isLoading: false });
    }
  }

  getSessionExam() {
    return JSON.parse(localStorage.getItem("ppltrainer_exam_session"));
  }

  setSessionExam(object) {
    if (!_.isObject(object)) console.error("Can not set localStorage");
    const data = JSON.stringify(object);
    localStorage.setItem("ppltrainer_exam_session", data);
  }

  // for simulation: On select exam category set category and get questions
  getQuestionsForSimulationFromKey(category) {
    this.setState({
      isLoading: true,
      categories: [category],
    }, () => this.prepareQuestions());
  }

  getRandomNumbers (count, max, options) {
    if (max < count) count = max;
    var exclude = (options && options.exclude) || [];
    count = count - exclude.length;
    var arr = [];
    while(arr.length < count){
        var r = Math.floor(Math.random() * Math.floor(max));
        if(arr.indexOf(r) === -1 && exclude.indexOf(r) === -1) arr.push(r);
    }
    return arr;
  }

  prepareQuestions(options) {
    const mergedQuestions = [];
    const questions = {};
    const that = this;

    _.each(this.state.categories, function (category) {
      const settings = that.getLocalStorageData();
      let q_lang = (settings.language || "de").toLowerCase(); // default language is de because this is a new property
      let dataCategory = category;

      // full list of all questions to a category
      let questionDB = that.state.questions.data[dataCategory];

      const isFlightSchoolCategory = category.split("_");
      if (isFlightSchoolCategory[0] === "FS") {
        dataCategory = isFlightSchoolCategory[1];
        q_lang = "flightschool_questions";
        questionDB = that.state.questions.flightschool_questions[dataCategory];
      }

      if (isFlightSchoolCategory[0] === "RADIO") {
        dataCategory = category.split(/_(.+)/)[1];
        q_lang = "radio_questions";
        questionDB = that.state.questions.radio_questions[dataCategory];
      }

      if (isFlightSchoolCategory[0] === "OLDER") {
        dataCategory = category.split(/_(.+)/)[1];
        q_lang = "older_questions";
        questionDB = that.state.questions.older_questions[dataCategory];
      }

      if (isFlightSchoolCategory[0] === "NVFR") {
        dataCategory = isFlightSchoolCategory[0];
        q_lang = "older_questions";
        questionDB = that.state.questions.older_questions[dataCategory];
      }
      
      if (questionDB) {
        let questionDbData = questionDB.data;

        // fill with available questions (e.g. trainingsmode excludes right answered questions, simulation includes all)
        let categoryQuestions = that.preparedQuestionObject(category, questionDbData);
        
        if (that.state.mode === "simulation") {

          const sessionExam = that.getSessionExam();
          const sessionExamDataQuestions = (sessionExam && sessionExam[category]) || false;
          let alreadyGivenQuestions;
          if (sessionExamDataQuestions) {
            const wrongQuestions = sessionExamDataQuestions.wrong;
            const rightQuestions = sessionExamDataQuestions.right;
            const listA = _.map(wrongQuestions, function (data) { return parseInt(Object.keys(data)[0].split("_")[1]) });
            const listB = _.map(rightQuestions, function (data) { return parseInt(Object.keys(data)[0].split("_")[1]) });
            alreadyGivenQuestions = listA.concat(listB);
          }

          // get random exam questions and reminder counter if there are already given answers
          var questionCount = TOTAL_EXAM_QUESTIONS;
          if (isFlightSchoolCategory[0] === "RADIO") {
            questionCount = TOTAL_RADIO_EXAM_QUESTIONS;
          }

          // overwrite questionCount with homework question count
          if (that.props.homeworkQuestionCount) {
            questionCount = that.props.homeworkQuestionCount;
          }

          // TODO check older questions
                  
          var generateQuestionsOptions = { exclude: alreadyGivenQuestions };
          if (options && options.mode === "pdfGeneration") { generateQuestionsOptions = {}; }
          const randomNumbers = that.getRandomNumbers(questionCount, categoryQuestions[category].data.length, generateQuestionsOptions);
          let sim_questionDbData = [];
          _.each(randomNumbers, function(number) {
            sim_questionDbData.push(categoryQuestions[category].data[number]);
          });

          // TODO !!!!

          if (that.props.settings.showQuestionsFromFlightschool || that.state.isATO || that.state.isExamAndHasID) {
            q_lang = "flightschool_questions";
            const questionsFS = that.state.questions[q_lang] && that.state.questions[q_lang][category];
            if (questionsFS) {
              let questionsDataFS = questionsFS.data;
              let categoryQuestionsFS = that.preparedQuestionObject("FS_" + category, questionsDataFS);
              let mixArrayWithSim_questionDbData = _.clone(categoryQuestionsFS["FS_" + category].data);

              mixArrayWithSim_questionDbData = that.shuffleArray(mixArrayWithSim_questionDbData);

              let secretQuestions = _.filter(mixArrayWithSim_questionDbData, function(question) { return question.isSecret });
              secretQuestions = _.filter(secretQuestions, function(unused, index) { return index < TOTAL_FLIGHTSCHOOL_SECRET_QUESTIONS });
              let remainingPublicQuestionsCount = TOTAL_FLIGHTSCHOOL_PUBLIC_QUESTIONS;
              if (secretQuestions.length < TOTAL_FLIGHTSCHOOL_SECRET_QUESTIONS) {
                remainingPublicQuestionsCount = TOTAL_FLIGHTSCHOOL_PUBLIC_QUESTIONS + (TOTAL_FLIGHTSCHOOL_SECRET_QUESTIONS - secretQuestions.length)
              }

              let publicQuestions = _.filter(mixArrayWithSim_questionDbData, function(question) { return !question.isSecret });
              publicQuestions = _.filter(publicQuestions, function(unused, index) { return index < remainingPublicQuestionsCount });
              if (publicQuestions.length < TOTAL_FLIGHTSCHOOL_PUBLIC_QUESTIONS) {
                const publicQuestionsMissingCount = TOTAL_FLIGHTSCHOOL_PUBLIC_QUESTIONS - publicQuestions.length;
                let remainingSecretQuestions = _.filter(mixArrayWithSim_questionDbData, function(unused, index) { return index > TOTAL_FLIGHTSCHOOL_SECRET_QUESTIONS });
                remainingSecretQuestions = _.filter(remainingSecretQuestions, function(question) { return question.isSecret });
                remainingSecretQuestions = _.filter(remainingSecretQuestions, function(unused, index) {
                  return index < publicQuestionsMissingCount;
                });
                publicQuestions = publicQuestions.concat(remainingSecretQuestions);
              }

              const concatedSimQuestionDbData = secretQuestions.concat(publicQuestions);
              _.each(concatedSimQuestionDbData, function(el) {
                // convert FS_CATEGORY to CATEGORY
                var isFlightSchoolCategory = el.category.split("_");
                if (isFlightSchoolCategory[0] === "FS") {el.category = isFlightSchoolCategory[1] }
              });
              _.each(sim_questionDbData, function(qestion) {
                if (concatedSimQuestionDbData.length < TOTAL_EXAM_QUESTIONS) {
                  concatedSimQuestionDbData.push(qestion);
                }
              });

              // set calculated flightschool questions
              sim_questionDbData = that.shuffleArray(concatedSimQuestionDbData);
            }
          }

          categoryQuestions[category].data = sim_questionDbData;

          // Start analytics in LocalStorage with category
          const examAnalytics = that.getSessionExam();
          if (examAnalytics && !examAnalytics[category]) {
            examAnalytics[category] = {"right": [], "wrong": []};
          }
          that.setSessionExam(examAnalytics);
        }

        _.extend(questions, categoryQuestions);
      } // end if questionDB
    });

    var counterList = {};
    var totalQuestionCount = 0;
    _.each(questions, function(entry, key) {
      counterList[key] = { count: entry.data.length };
      _.each(entry.data, function(question) {
        mergedQuestions.push(question);
        totalQuestionCount++;
      });
    });

    if (options && options.mode === "pdfGeneration") {
      that.setState({
        isLoading: false,
        counterList,
        totalQuestionCount,
        mergedQuestionsForPDFExam: mergedQuestions
      }, () => that.generatePDF());
      return;
    }

    // *** Scenario Questions *** ///
    if (this.state.scenario) {
      const scenario = this.state.scenario;
      const scenarioID = scenario.id;
      const scenarioQuestions = scenario.questions;
      let questionCount = 1;
      for (const entry of scenarioQuestions) {
        const scenarioQuestion = {
          "key": "Scenario_" + questionCount,
          "originalKey": entry.questionID,
          "category": scenarioID,
          "question": entry.question,
          "correctAnswer": entry.correctAnswerIndex,
          "answers": entry.answers,
          "Syllabus": "Scenario",
          "Topic": "Scenario",
          "img": entry.image,
        }
        questionCount++;
        mergedQuestions.push(scenarioQuestion);
      }
    }

    that.setState({ 
      mergedQuestions,
      isLoading: false
    });
  }
  
  shuffleArray(array) {
    return array.sort( () => Math.random() - 0.5)
  }

  getOrCreateLocalStorageQuestionDatabase(category) {
    const data = this.getLocalStorageItem(category);
    if (data) return data;
    
    localStorage.setItem(category, "[]");
    return this.getLocalStorageItem(category);
  }

  getLocalStorageItem(category) {
    const data = JSON.parse(localStorage.getItem(category));
    if (data) return data;
    return null;
  }

  parseCorrectAnswer(value) {
    if (value === "A") return 0;
    if (value === "B") return 1;
    if (value === "C") return 2;
    return 3;
  }

  getQuestionPicture(question) {
    // return Picture or Annex - don't difference between pictures and annex
    if (question["Picture"] && question["Picture"].length) return [question["Picture"]];
    if (question["Annex"] && question["Annex"].length) {
      const annex = question["Annex"].split(","); // return all available images and handle the rendering in Question.js
      return _.unique(annex);
    }
    if (question.img) {
      // image from flightschool
      return { "type": "url", "url": question.img };
    }
    return false;
  }

  getLocalStorageData() {
    return JSON.parse(localStorage.getItem('ppltrainer'));
  }

  headerExistsInCategory(header, keys, key) {
    if (!header) return false;
    const keyList = keys[key];
    const found = _.find(keyList, function (el) { return !_.isUndefined(header[el]) });
    return found;
  }

  preparedQuestionObject(category, data) {
    const that = this;
    const obj = {};
    let filteredData = null;
    let unionQuestionDatabaseList = [];

    // do the stuff if it's not a flightschool category
    const isFlightSchoolCategory = category.split("_");
    const customCategory = isFlightSchoolCategory[0];
    const isFlightSchool = ["FS","RADIO","OLDER","NVFR"].indexOf(customCategory) >= 0;

    if (!isFlightSchool) {
      // get categories from CSV (new and old excel layout) like "PPLA", "02-PPLA-TR", ...
      unionQuestionDatabaseList = utils.getRequestedDatabaseQuestions(data[0]);
    }

    // get completed questions from local-storage by id
    const completedQuestionsFromLocalStorage = that.getOrCreateLocalStorageQuestionDatabase(category);

    filteredData = _.filter(data, function (q) {
      let list = null;

      list = _.find(unionQuestionDatabaseList, function (cat) { return q[cat] && q[cat].length });
      if ((list && that.props.mode === "simulation") || (isFlightSchool && that.props.mode === "simulation")) {
        return true; // if found and is simulation return ALL
      }

      const fullCategory = q["Question ID"] ? category + "_" + q["Question ID"] : q.key;
      const foundInLocalStorage = completedQuestionsFromLocalStorage.indexOf(fullCategory) >= 0;
      if (isFlightSchool) return !foundInLocalStorage;
      return list && !foundInLocalStorage;
    });

    obj[category] = {
      "data": _.map(
        filteredData,
        function (question) {
          const entry = {};
          const key = question["Question ID"] ? category + "_" + question["Question ID"] : question.key;
          entry.key = key;
          entry.originalKey = question["Question ID"];
          entry.category = category;
          entry.question = question["Question"] || question.question;
          entry.correctAnswer = question["Correct"] ? that.parseCorrectAnswer(question["Correct"]) : (question.correctAnswer || 0);
          entry.answers = question.answers || [question["A"],question["B"],question["C"],question["D"]];

          entry.Syllabus = question["Syllabus"] || "";
          entry.Topic = question["Topic"] || i18n("my_flightschool");

          if (question.isSecret) entry.isSecret = true;
          if (that.getQuestionPicture(question)) entry.img = that.getQuestionPicture(question);

          return entry;
        })
    };
    return obj;
  }

  onReset(options) {
    let confirmMessage = "do_you_really_want_to_skip";
    if (this.state.mode === "simulation") {
      confirmMessage = "do_you_really_want_to_skip_the_test";
      this.clearSessionExamItem();
    }
    if (options && options.forceReset) {
      this.props.onReset();
    } else if (window.confirm(i18n(confirmMessage))) {
      this.props.onReset();
    }
  }

  backToSimulationOverview() {
    this.setState({ mergedQuestions: null });
  }

  getTranslationFromFlightSchoolCategories(key) {
    var { flightschool_categories } = this.props;
    var item = _.find(flightschool_categories, function (category) {
      return "FS_" + category.id === key; 
    });
    return (item && item.category) || false;
  }

  getTranslationForRadioCategories(key) {
    switch(key) {
      case "DE_bzf1":
        return "BZF I";
      case "DE_azf":
        return "AZF";
      case "DE_bzf2":
        return "BZF II";
      case "AT_bfz":
        return "BFZ";
      case "AT_efz":
        return "EFZ";
      case "AT_afz":
        return "AFZ";
      default:
        return false;
      }
  }

  getTranslation(value) {
    var preTranslation = i18n(value);
    if (preTranslation) return preTranslation;
    var key = value.split("_")[1] || value;
    var translatedValue = i18n(value);
    return  translatedValue || this.getTranslationFromFlightSchoolCategories(key) || this.getTranslationForRadioCategories(key) || key;
  }

  createExamForPrinter(content, categories) {
    const that = this;
    this.setState({
      pdfContent: content,
      isLoading: true,
      categories,
    }, () => that.prepareQuestions({ mode: "pdfGeneration" }));
  }

  shuffleAnswers(orig) {
    let a = orig.slice();
    for (let i = a.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [a[i], a[j]] = [a[j], a[i]];
    }
    return a;
  }

  generatePDF() {
    const that = this;
    const topics = this.state.categories;
    const questions = this.state.mergedQuestionsForPDFExam;

    // suffle question answers
    _.each(questions, function (question) {
      const correctAnswer = question.answers[question.correctAnswer];
      question.answers = that.shuffleAnswers(question.answers);
      question.correctAnswer = question.answers.indexOf(correctAnswer);
    });

    this.setState({ isLoading: true });

    if (_.isEmpty(questions)) {
      // Cannot print
      this.setState({ isLoading: false });
      return false;
    }

    this.showPdf(questions, topics, "PPL Trainer Exam", null);
    this.showPdf(questions, topics, "Solutions PPL Trainer Exam", true);
  }

  getUpperCasedImage(img) {
    const fullImg = (img && img.toUpperCase && img.toUpperCase()) || img;
    const image = fullImg.split(".")[0] + ".jpg"; // split UPPERCASE.jpg
    return image;
  }

  async showPdf(questions, topics, pdfName, isAnswerSheet) {
    const that = this;

    debugger
    const counterList = this.state.counterList;
    const totalQuestionCount = this.state.totalQuestionCount;

    let content = [];
    if (!isAnswerSheet) {
      content = [
        {
          text: "Vorprüfung",
          style: 'header',
        },
        {
          text: 'Flugschule: ' +  this.state.atoFullName || "PPL Trainer",
          style: 'subheader'
        },
        {
          style: 'nameSection',
          table: {
            body: [
              ['Vorname, Nachname:', ''],
            ]
          },
          layout: 'noBorders'
        },
        {
          style: 'dateSection',
          table: {
            body: [
              ['Datum:', '']
            ]
          },
          layout: 'noBorders'
        },
        {
          style: 'signature',
          table: {
            body: [
              ['Unterschrift:', '']
            ]
          },
          layout: 'noBorders'
        },
        {
          text: 'Zusammenfassung der Vorprüfung',
          style: 'result',
        },
        {
          style: 'resultSummary',
          table: {
            widths: [14, 220, '*', '*'],
            heights: 20,
            body: 
              _.map(topics, function (topic, index) {
                const value = i18n(topic) || (topic.split("_") && (i18n(topic.split("_")[1]) || topic.split("_")[1]));
                return [index + 1, value, '_______ /' + counterList[topic].count + ' Fragen', '_______ %'];
              })
            
          },
          layout: 'noBorders'
        },
        {
          style: 'fullResultSummary',
          table: {
            widths: [14, 220, '*'],
            heights: 30,
            body: [
              [{text: ''},  {text: 'Gesamt:', alignment: 'right'}, {text: '_________  / ' + totalQuestionCount + ' Fragen      _________ %'}],
            ]
          },
          layout: 'noBorders'
        },
      ]; 
    }

    let kategory = "";
    let index = 0;

    for (const item of questions) {
      if (item.category !== kategory) {
        kategory = item.category;
        index = 0;
        if (isAnswerSheet && index === 1) {} else {
          content.push({
            text: '', 
            pageBreak: 'before'
          });
        }
        content.push({
          text: i18n(item.category) || (item.category.split("_") && (i18n(item.category.split("_")[1]) || item.category.split("_")[1])),
          style: 'kategorie-headline'
        });
      }
      const { question, answers, correctAnswer } = item;
      index++;
      content.push({
          style: 'question',
          text: index + ') ' + question
        },{
          style: 'answers',
          table: {
            body: that.getAnswersBody(answers, correctAnswer, isAnswerSheet)
          },
          layout: 'noBorders'
        });

        var image;
        // TODO check img (object object)
        if (item.img) {
          if (item.img.url) {
            var imgUrl = item.img.url;
            image = await this.getBase64ImageFromURL(imgUrl);
            content.push({
              image: image,
              style: 'images'
            });
          } else {
            var img = item.img;

            // handle multiple images in array
            if (_.isArray(img)) {
              img = img[0];
              img = that.getUpperCasedImage(img);
              const imgPath = '/data/24.6/documents/'+img;
              const image = await that.getBase64ImageFromURL(imgPath);
              console.log(image);
              content.push({
                image,
                style: 'images'
              });
            } else {
              img = that.getUpperCasedImage(img);
              var imgPath = '/data/24.6/documents/'+img;
              image = await this.getBase64ImageFromURL(imgPath);
              content.push({
                image: image,
                style: 'images'
              });
            }
          }
        }
    }
    
    const dd = {
      footer: function(currentPage, pageCount) {
        return {
            margin:0,
            columns: [
            {
                fontSize: 9,
                text:[
                {
                text: "Seite " + currentPage.toString() + " von " + pageCount,
                margin: [10,20]
                }
                ],
                alignment: 'center'
            }
            ]
        };
      },
      content: content,
      styles: {
        header: {
          fontSize: 16,
          bold: true,
          alignment: 'center',
          margin: [0, 15, 0, 0]
        },
        "kategorie-headline": {
          fontSize: 16,
          bold: true,
          alignment: 'left',
          margin: [0, 20, 0, 0]
        },
        "question": {
          bold: true,
          margin: [0, 25, 0, 7],
          fontSize: 10
        },
        subheader: {
          fontSize: 11,
          alignment: 'center',
          margin: [0, 2, 0, 0]
        },
        result: {
          fontSize: 12,
          bold: true,
          alignment: 'left',
          margin: [20, 40, 0, 0]
        },
        answers: {
          fontSize: 10
        },
        fullResultSummary: {
          fontSize: 12,
          bold: true,
          margin: [-5, 30, 0, 0]
        },
        footer: {
          fontSize: 9,  
        },
        nameSection: {
          margin: [20,30,0,0]
        },
        dateSection: {
          margin: [20,5,0,0]
        },
        signature: {
          margin: [20,25,0,0]
        },
        images: {
          margin: [0,15,20,0]
        },
        resultSummary: {
          margin: [20,15,0,0],
          fontSize: 11
        },
      }
    };
    
    // pdfMake.createPdf(dd).open();
    pdfMake.createPdf(dd).download(pdfName, function() { 
      that.setState({ isLoading: false });
    });
  }

  getBase64ImageFromURL(url) {
    return new Promise((resolve, reject) => {
      var img = new Image();
      img.setAttribute("crossOrigin", "anonymous");
      img.onload = () => {

        // page size 595,28 x 841,89
        var { width, height } = img;

        var canvas = document.createElement("canvas");
        var ctx = canvas.getContext("2d");

        ctx.imageSmoothingEnabled = false;

        if (this.shouldRotate(img)) {

          if (width > 750) {
            let ratio = Math.min(750 / img.width, 950 / img.height);
            height = parseInt(img.height * ratio, 10);
            width = parseInt(img.width * ratio, 10);
            if (height > 500) {
              ratio = Math.min(750 / width, 500 / height);
              height = parseInt(height * ratio, 10);
              width = parseInt(width * ratio, 10);
            }
          }

          canvas.height = width;
          canvas.width = height;
          ctx.translate(canvas.width/2, canvas.height/2);
          ctx.rotate(-90*Math.PI/180);
          ctx.drawImage( img, -(canvas.height)/2, -(canvas.width)/2, canvas.height, canvas.width);

          resolve(canvas.toDataURL("image/png"));

        } else {
          if (this.imageIsToLarge(width, height)) {
            // recalculate width and size (if scaling is needed and width is correct)
            canvas.width = width/2;
            canvas.height = height/2;
          } else {
            // just draw the image
            canvas.width = width;
            canvas.height = height;
          }
          
          ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
          resolve(canvas.toDataURL("image/png"));
        }

      };
      img.onerror = error => {
        reject(error);
      };
      img.src = url;
    });
  }

  imageIsToLarge(width, height) {
    if (width > 560 || height > 800) return true;
  }

  shouldRotate(img) {
    var { width, height } = img;
    // TODO viele bilder sind fast quadratisch (maps), diese sollten ev. nicht rotated werden
    return width > height && width > 560;
  }

  getAnswersBody(answers, correctAnswer, isAnswerSheet) {
    var that = this;
    const answerList = [];
    _.each(answers, function (answer, index) {
      let image = svgs.answerSVG;
      if (index === correctAnswer && isAnswerSheet) {
        image = svgs.correctAnswerSVG;
      }
      answerList.push([
        {
          image: image,
	        width: 11
        },
        { text: that.getAlpabetcChar(index + 1) + ")" },
        { text: answer }
      ]);
    });
    return answerList;
  }

  getAlpabetcChar(index) {
    // eslint-disable-next-line default-case
    switch(index) {
      case 1:
        return 'A';
      case 2: 
        return 'B';
      case 3: 
        return 'C';
      case 4: 
        return 'D';
    }
  }

  getUserEmail() { return this.props.userinfo.idToken.claims.email }

  async saveExamAsHomework(options) {
    await API.addHomework(options);
  }
  
  async saveExamAsExam(options) {
    await API.addFinishedExams(options);
  }

  async sendExam() {
    const that = this;
    const time = new Date().toLocaleTimeString();
    const date = new Date().toLocaleDateString();
    const dateAndTime = date + ", " + time;
    var userinfo = JSON.parse(localStorage.getItem('okta-token-storage'));
    let globalResult = i18n("passed");
    let status_of_work = "passed"; // as key, to work with colors

    let firstname = userinfo.idToken.claims.firstname;
    let lastname = userinfo.idToken.claims.lastname;
    let details = null;
    let name = (firstname+ ", " + lastname) || i18n("not_available");
    let recordTitle = i18n("record_of_the_theoretical_exam");

    let ato_name = null;
    let ato_details = null;
    let isATO = null;

    if (this.state.user) {
      firstname = this.state.user.firstname;
      lastname = this.state.user.lastname;
      details = this.state.user.details;
      name = firstname + ", " + lastname;

      ato_name = this.state.atoFullName;

      if (details) ato_details = details;
      isATO = true;
      recordTitle = i18n("record_ot_the_pre_check");
    }

    const company = this.state.atoFullName || "PPL Trainer";

    const data = this.getSessionExam();
    const listOfCategories = [];

    const listOfQuestions = [[i18n("question"), i18n("correct_answer"), i18n("your_answer"), i18n("status")]];
    
    _.each(data, function (catEntry, key) {
      var categoryQuestionRightWrong = catEntry;

      let sum = categoryQuestionRightWrong.right.length + categoryQuestionRightWrong.wrong.length;

      const right = categoryQuestionRightWrong.right;
      const earned = right.length;
      const perc= ((earned/sum) * 100);
      const percentage = Math.floor(perc) + "%";

      let resultOfCategory = i18n("passed");
      if (perc < POSITIVE_PERCENTAGE_NUMBER) {
        resultOfCategory = i18n("not_passed");
        globalResult = i18n("not_passed");
        status_of_work = "not_passed"; // as key for colors
      }

      listOfCategories.push([
        that.getTranslation(key),
        date,
        percentage,
        resultOfCategory,
      ]);

      // Get List of all questions with correct/false answer
      _.each(categoryQuestionRightWrong, function(entry) {
        _.each(entry, function(element) {
          _.each(element, function(el) {
            const givenAnswer = el.givenAnswer;
            const status = el.status;
            const question = el.question;
            const answer = el.answer;
            listOfQuestions.push([
              question,
              answer,
              givenAnswer,
              status
            ]);
          });
        });
      });

    });

    const nameLabel = i18n("firstName") + ", " + i18n("lastNameShort") + ":";
    const content = [
      {
      image: 'logo',
      width: 140
      },
      {
        text: recordTitle,
        style: 'header'
      },
      {
        text: 'ECQB PPL - by ' + company,
        style: 'subheader'
      },
      {
        style: 'tableExample',
        table: {
          body: [
            [nameLabel, name],
            [i18n("date_of_exam") + ": ", dateAndTime]
          ]
        },
        layout: 'noBorders'
      },
      {
        text: i18n("details_of_the_exam"),
        style: 'result',
      },
      {
        style: 'tableExample',
        table: {
            widths: [260, '*', '*', 90],
          body: listOfCategories,
        },
        layout: 'noBorders'
      },
      {
        style: 'result',
        text: [
            i18n("result_of_the_exam"),
            ': ',
            {text: globalResult, fontSize: 16},
          ]
      },
      { 
        text: i18n("details_flashcard"), 
        style: 'header', 
        pageBreak: 'before', 
        pageOrientation: 'landscape' 
      },
      {
          text: i18n("here_you_will_find_a_detailed_report_of_your_answers"), 
          style: 'subheader', 
      },
      {
        style: 'tableExample2',
        table: {
          widths: [260, '*', '*', 50],
          body: listOfQuestions,
        }
      }
    ];

    if (isATO) {
      content[3].table.body.push(["ATO: ", ato_name]);
      if (ato_details) {
        content[3].table.body.push([i18n("info") + ": ", ato_details]);
      }
    }

    details = details ? " (" + details + ") " : " ";
    const dd = {
      footer: function(currentPage, pageCount) {
        const pageLabel = i18n("page_x_of_y").replace("{{x}}", currentPage.toString()).replace("{{y}}", pageCount);
        return {
            margin:0,
            columns: [
            {
                fontSize: 9,
                text:[
                {
                text: firstname + " " + lastname + details + "- " + dateAndTime + " | " + pageLabel,
                margin: [0,20]
                }
                ],
                alignment: 'center'
            }
            ]
        };
      },
      content: content,
      images: {
        logo: svgs.logoSVG,
      },
      styles: {
        header: {
          fontSize: 16,
          bold: true,
          alignment: 'center',
          margin: [0, 40, 0, 0]
        },
        result: {
          fontSize: 12,
          bold: true,
          alignment: 'left',
          margin: [0, 40, 0, 0]
        },
        subheader: {
          fontSize: 11,
          alignment: 'center',
        },
        footer: {
          fontSize: 9,  
        },
        smallheadline: {
          fontSize: 10  ,
          margin: [0,10,0,0]
        },
        tableExample: {
          margin: [0,30,0,0]
        },
        tableExample2: {
          fontSize: 10,  
          margin: [0,20,0,0]
        }
      }
    };

    try {
      // Homework vs. self created exam
      if (this.props.isHomeworkAndHasID) {
        await this.saveExamAsHomework({
          id: this.props.isHomeworkAndHasID,
          dd: JSON.stringify(dd),
          firstname,
          lastname,
          date: dateAndTime,
          globalResult,
          status_of_work
        });
      } else if (this.props.isExamAndHasID) {
        await this.saveExamAsExam({
          id: this.props.isExamAndHasID,
          dd: JSON.stringify(dd),
          firstname,
          lastname,
          date: dateAndTime,
          globalResult,
          status_of_work
        });
      } else {
        await this.saveExamToGlobalExamList({dd, firstname, lastname, date: dateAndTime, globalResult, status_of_work});
      }

    } catch(e) {
      console.warn(e);
    }

    this.clearSessionExamItem();

    pdfMake.createPdf(dd).download(`ppltrainer-exam-${dateAndTime}.pdf`);

    this.onReset({ forceReset: true });
  }

  async saveExamToGlobalExamList(options) {
    let storageExams = localStorage.getItem("ppltrainer_exam_storage");
    if (storageExams === '') {
      storageExams = '[]';
    }
    var exams = JSON.parse(storageExams) || [];
    exams.unshift(options);
    if (exams.length > 3) {
      exams.pop();
    }
    const data = JSON.stringify(exams);
    localStorage.setItem("ppltrainer_exam_storage", data);
    await API.syncExams();
  }

  clearSessionExamItem() {
    localStorage.setItem("ppltrainer_exam_session", "{}");
  }

  updateATODetails(options) {
    const { printNewExam, details, firstname, lastname, totalCount, totalCountRadio, percentage } = options;
    this.setState({
      user: {
        firstname: firstname,
        lastname: lastname,
        details: details,
        totalCount: totalCount,
        totalCountRadio: totalCountRadio,
      },
      printNewExam
    });
    this.updateQuestionCounts(totalCount, totalCountRadio);
    this.updateExamPercentage(percentage);
  }

  updateQuestionCounts(totalCount, totalCountRadio) {
    TOTAL_EXAM_QUESTIONS = totalCount || 16;
    TOTAL_FLIGHTSCHOOL_QUESTIONS = parseInt(TOTAL_EXAM_QUESTIONS / 4);
    TOTAL_FLIGHTSCHOOL_SECRET_QUESTIONS = parseInt(TOTAL_FLIGHTSCHOOL_QUESTIONS * 0.75);
    TOTAL_FLIGHTSCHOOL_PUBLIC_QUESTIONS = parseInt(TOTAL_FLIGHTSCHOOL_QUESTIONS - TOTAL_FLIGHTSCHOOL_SECRET_QUESTIONS);
    TOTAL_RADIO_EXAM_QUESTIONS = totalCountRadio || totalCount || 16;
  }

  updateExamPercentage(percentage) {
    POSITIVE_PERCENTAGE_NUMBER = percentage || 75;
  }

  render() {

    // TODO also ask for restoring the exam for non ato users
    if (this.state.isLoading) return <Loading />
    return (
      <div>
        <div className="Overview clearfix"></div>
        <div className="QuizTemplate content-container">

          { this.state.mode === "simulation" ?
            (this.state.isATO && this.state.user) || !this.state.isATO || this.props.printExam?
              <SimulationOverview
                onSelectCategory={this.getQuestionsForSimulationFromKey}
                isATO={this.state.isATO}
                atoFullName={this.state.atoFullName}
                questions={this.state.mergedQuestions}
                categories={this.state.categories}
                homeworkCategories={this.props.homeworkCategories}
                homeworkQuestionCount={this.props.homeworkQuestionCount}
                homeworkExamPercentage={this.props.homeworkExamPercentage}
                isHomeworkAndHasID={this.props.isHomeworkAndHasID}
                isExamAndHasID={this.props.isExamAndHasID}
                radio_categories={this.props.radio_categories}
                radio_questions={this.props.questions && this.props.questions.radio_questions}
                older_questions={this.props.questions && this.props.questions.older_questions}
                flightschool_categories={this.props.flightschool_categories}
                flightschool_questions={this.props.questions && this.props.questions.flightschool_questions}
                settings={this.props.settings}
                mode={this.state.mode}
                backToSimulationOverview={this.backToSimulationOverview}
                sendExam={this.sendExam}
                user={this.state.user}
                printNewExam={this.state.printNewExam || this.props.printExam}
                onPrintNewExam={this.createExamForPrinter}
              />
              :
              <SetATODetails userinfo={this.props.userinfo} onChange={this.updateATODetails} />
            :
            <QuestionContent
              questions={this.state.mergedQuestions}
              flightschool_answers={this.props.flightschool_answers}
              flightschool_documents={this.props.flightschool_documents}
              categories={this.state.categories}
              settings={this.props.settings}
              mode={this.state.mode}
              abort={this.onReset}
            />
          }

          <button className="btn-second btn-abort-top" onClick={this.onReset} >{i18n("cancel")}</button>
        </div>
      </div>
    );
  }
}

export default QuizTemplate;
